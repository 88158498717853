import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../lib/api";
import { QNA_LIST } from "../../common/api-codes";
import { useState } from "react";
import * as S from "../../components/molecules/Modal/modal.style";
import { Button } from "../../components/atoms/Button";
interface QnAModalProps {
  onClose: () => void;
}

const QnAModal = ({ onClose }: QnAModalProps) => {
  const queryClient = useQueryClient();
  const [question, setQuestion] = useState("");
  const handleSubmit = () => {
    if (question.length < 10) {
      alert("질문 내용을 10글자 이상 입력해 주세요.");
      return;
    }
    createQuestion(question);
  };

  const { mutateAsync: createQuestion, isLoading } = useMutation({
    mutationFn: (data: any) => {
      return request({
        method: "POST",
        url: "api/v1/qna/question",
        data: {
          question,
        },
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [QNA_LIST] });
      onClose();
    },
    onError: (e: { message: number; error: string }) => {
      alert(e?.error || "서버요청 에러");
    },
  });

  return (
    <S.ModalBG>
      <S.ModalBox size="lg">
        <div>
          <S.ModalHeader>
            <S.ModalTitle>질문 작성하기</S.ModalTitle>
          </S.ModalHeader>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
              <div style={{ fontSize: "1.7rem", fontWeight: "600" }}>질문</div>
              <div style={{ fontSize: "1.3rem", color: "#aaa" }}>{question.length}/200자</div>
            </div>
            <div>
              <textarea
                maxLength={200}
                style={{
                  fontSize: "1.7rem",
                  width: "100%",
                  height: "15rem",
                  resize: "none",
                  borderColor: "#ccc",
                }}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            width="100%"
            height={46}
            color="subTxt"
            fontSize={1.6}
            directColumn={false}
            label="닫기"
            onClick={onClose}
          />
          <div style={{ width: 10 }} />
          <Button
            width="100%"
            height={46}
            color="primary"
            fontSize={1.6}
            directColumn={false}
            label="등록"
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </div>
      </S.ModalBox>
    </S.ModalBG>
  );
};

export default QnAModal;
